import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    ApplicationApiFailed,
    ApplicationApprovedWaitingForFunds,
    ApplicationBoarded,
    ApplicationClosed,
    ApplicationClosedAwaitingRefund,
    ApplicationDenied,
    ApplicationDepositApproved,
    ApplicationDocumentsRequested,
    ApplicationExpiredOrIncomplete,
    ApplicationFundingDeclined,
    ApplicationFundingFail,
    ApplicationInGoodFunds,
    ApplicationManualReview,
    ApplicationManualDocumentReview,
    ApplicationSubmitted,
    ApplicationWithdrawn,
    BasicInfo,
    CheckApplication,
    CreditLine,
    CreditLineConfirm,
    CreditLineCustom,
    CreditLineSwitch,
    CreditLineFundLater,
    DebtToIncome,
    DisclosureViewer,
    DocumentSelection,
    ErrorPage,
    FundingSource,
    FundingPayment,
    IdentityConfirm,
    IdentityVerification,
    IntroGeneric,
    IntroBasic,
    IntroPlus,
    IntroLaunch,
    InvalidEmail,
    LexisMiskey,
    MailingAddress,
    OtpCheck,
    ProcessApplication,
    SubmitApplication,
    TermsConditions,
    ViewApplication,
    WithdrawApplication,
    Downsell
} from './pages';
import {
    ErrorPageProps
} from './types';
import './scss/app.scss';
import { useRecoilValue } from 'recoil';
import { launchCardTunerdOnState } from './store/atoms';

export const entryPoints = [
    // keep these lowercase to compare entryPoints in app.tsx
    '/opensky/apply',
    '/securedplus/apply',
    '/secured/apply',
    '/launch/apply',
    '/check-application-status',
    '/fund-your-card',
    '/upload-documents',
    '/app/checkapplicationstatus',
    '/app/paywithdebit',
    '/documents.aspx',
    '/app-check',
    '/pages/applicationscreen1'
];

export const routeHashes = {
    '/Secured/Apply': '/Secured/Apply', // don't hash entry points
    '/SecuredPlus/Apply': '/SecuredPlus/Apply', // don't hash entry points
    '/Launch/Apply': '/Launch/Apply',
    '/OpenSky/Apply': '/OpenSky/Apply', // don't hash entry points
    '/app-approved-boarded': '/app-boarded',
    '/app-approved-deposit-approved': '/app-deposited',
    '/app-approved-in-good-funds': '/app-funded',
    '/app-approved-waiting-for-funds': '/app-waiting-for-funds',
    '/app-closed': '/app-closed',
    '/app-closed-expired-or-incomplete': '/app-closed-expired',
    '/app-closed-waiting-on-refund': '/app-closed-waiting-on-refund',
    '/app-closed-withdrawn': '/app-closed-withdrawn',
    '/app-denied': '/app-thank-you-a',
    '/app-funding-declined': '/app-thank-you-b',
    '/app-funding-fail': '/app-thank-you-c',
    '/app-pending-api-failed': '/app-pending-api-failed',
    '/app-pending-manual-document-review': '/app-document-review',
    '/app-pending-manual-review': '/app-review',
    '/app-submitted': '/app-submitted',
    '/basic-info': '/about-me',
    '/check-application-status': '/app-check', // don't hash entry points
    '/credit-line': '/credit-line-options',
    '/credit-line-confirm': '/credit-line-confirm',
    '/credit-line-custom': '/credit-line-custom',
    '/credit-line-fund-later': '/credit-line-fund-later',
    '/credit-line-switch': '/credit-line-switch',
    '/debt-to-income': '/get-options',
    '/disclosures': '/disclosures',
    '/document-selection': '/doc-select',
    '/documents-requested': '/doc-request',
    '/error': `/${uuidv4()}`,
    '/field-corrections': '/correct-info',
    '/funding-source': '/funding-source',
    '/funding-payment': '/funding-payment',
    '/identity-confirm': '/id-confirm',
    '/identity-verification': '/id-verify',
    '/invalid-email': '/correct-email',
    '/mailing-address': '/mailing-address',
    '/otp-check': '/otp-check',
    '/submit-application': '/app-submit',
    '/terms-and-conditions': '/terms-and-conditions',
    '/processing-application': '/app-process',
    '/view-application': '/view-application',
    '/withdrawal': '/app-withdraw',
    '/launch-downsell': '/launch-downsell'
};

export const checkForUnauthorized = (error: any, history: any): boolean => {
    const unauthorizedStatusCodes = [401, 403];

    if (unauthorizedStatusCodes.includes(error.response?.status ?? -1)) {
        history.replace(routeHashes['/error'], {
            title: 'For security reasons, your session with OpenSky has expired.',
            buttonText: 'Ok, Got it',
            navigateTo: `${process.env.REACT_APP_HOMEPAGE_URL}`,
        } as ErrorPageProps);

        return true;
    }

    return false;
};

const AppRoutes = () => {
    const launchCardTunedOn = useRecoilValue(launchCardTunerdOnState);
    const location = useLocation();

    useEffect(() => {
        const intellimize = window.intellimize;
        if (intellimize && typeof intellimize.ready === 'function') {
            intellimize.ready(() => {
                intellimize.activate();
            });
        }
    }, [location]);

    return (
        <Switch>
            <Route exact path={routeHashes["/OpenSky/Apply"]} component={IntroGeneric} />
            <Route exact path={routeHashes["/SecuredPlus/Apply"]} component={IntroPlus} />
            <Route exact path={routeHashes["/Secured/Apply"]} component={IntroBasic} />
            { launchCardTunedOn && <Route exact path={routeHashes["/Launch/Apply"]} component={IntroLaunch} /> }
            <Route exact path={routeHashes['/credit-line']} component={CreditLine} />
            <Route exact path={routeHashes['/credit-line-confirm']} component={CreditLineConfirm} />
            <Route exact path={routeHashes['/credit-line-custom']} component={CreditLineCustom} />
            <Route exact path={routeHashes['/disclosures']} component={DisclosureViewer} />
            <Route exact path={routeHashes['/terms-and-conditions']} component={TermsConditions} />
            <Route exact path={routeHashes['/view-application']} component={ViewApplication} />
            <Route exact path={routeHashes['/app-approved-boarded']} component={ApplicationBoarded} />
            <Route exact path={routeHashes['/app-approved-deposit-approved']} component={ApplicationDepositApproved} />
            <Route exact path={routeHashes['/app-approved-in-good-funds']} component={ApplicationInGoodFunds} />
            <Route exact path={routeHashes['/app-approved-waiting-for-funds']} component={ApplicationApprovedWaitingForFunds} />
            <Route exact path={routeHashes['/app-closed']} component={ApplicationClosed} />
            <Route exact path={routeHashes['/app-closed-expired-or-incomplete']} component={ApplicationExpiredOrIncomplete} />
            <Route exact path={routeHashes['/app-closed-waiting-on-refund']} component={ApplicationClosedAwaitingRefund} />
            <Route exact path={routeHashes['/app-closed-withdrawn']} component={ApplicationWithdrawn} />
            <Route exact path={routeHashes['/app-denied']} component={ApplicationDenied} />
            <Route exact path={routeHashes['/app-funding-declined']} component={ApplicationFundingDeclined} />
            <Route exact path={routeHashes['/app-funding-fail']} component={ApplicationFundingFail} />
            <Route exact path={routeHashes['/app-pending-api-failed']} component={ApplicationApiFailed} />
            <Route exact path={routeHashes['/app-pending-manual-document-review']} component={ApplicationManualDocumentReview} />
            <Route exact path={routeHashes['/app-pending-manual-review']} component={ApplicationManualReview} />
            <Route exact path={routeHashes['/app-submitted']} component={ApplicationSubmitted} />
            <Route exact path={routeHashes['/basic-info']} component={BasicInfo} />
            <Route exact path={routeHashes['/check-application-status']} component={CheckApplication} />
            <Route exact path={routeHashes['/credit-line-fund-later']} component={CreditLineFundLater} />
            <Route exact path={routeHashes['/credit-line-switch']} component={CreditLineSwitch} />
            <Route exact path={routeHashes['/debt-to-income']} component={DebtToIncome} />
            <Route exact path={routeHashes['/document-selection']} component={DocumentSelection} />
            <Route exact path={routeHashes['/documents-requested']} component={ApplicationDocumentsRequested} />
            <Route exact path={routeHashes['/error']} render={(props) => <ErrorPage {...(props.history.location.state as any)} />} />
            <Route exact path={routeHashes['/field-corrections']} component={LexisMiskey} />
            <Route exact path={routeHashes['/funding-source']} component={FundingSource} />
            <Route exact path={routeHashes['/funding-payment']} component={FundingPayment} />
            <Route exact path={routeHashes['/identity-confirm']} component={IdentityConfirm} />
            <Route exact path={routeHashes['/identity-verification']} component={IdentityVerification} />
            <Route exact path={routeHashes['/invalid-email']} component={InvalidEmail} />
            <Route exact path={routeHashes['/mailing-address']} component={MailingAddress} />
            <Route exact path={routeHashes['/otp-check']} render={(props) => <OtpCheck {...(props.history.location.state as any)} />} />
            <Route exact path={routeHashes['/submit-application']} component={SubmitApplication} />
            <Route exact path={routeHashes['/processing-application']} component={ProcessApplication} />
            <Route exact path={routeHashes['/withdrawal']} component={WithdrawApplication} />
            <Route exact path={routeHashes['/launch-downsell']} component={Downsell} />

            {/* routes from existing urls that need to map to check-application-status (note: these must also be added to entryPoints) */}
            <Route exact path="/fund-your-card">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/upload-documents">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/app/checkapplicationstatus">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/app/paywithdebit">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/documents.aspx">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/app-check">
                <Redirect to={routeHashes['/check-application-status']} />
            </Route>
            <Route exact path="/pages/applicationscreen1.aspx">
                <Redirect to={routeHashes['/Secured/Apply']} />
            </Route>

            {/* catch-all: redirect to application start */}
            <Route path="*">
                <Redirect to={routeHashes['/OpenSky/Apply']} />
            </Route>
        </Switch>
    );
};

export default AppRoutes;
